// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var timer = {
  contents: 0
};

function delay(fn, ms, args) {
  clearTimeout(timer.contents);
  timer.contents = setTimeout((function (param) {
          return Curry._1(fn, args);
        }), Belt_Option.getWithDefault(ms, 0));
  
}

export {
  timer ,
  delay ,
  
}
/* No side effect */
