// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";

function zip(opt1, opt2) {
  if (opt1 !== undefined && opt2 !== undefined) {
    return [
            Caml_option.valFromOption(opt1),
            Caml_option.valFromOption(opt2)
          ];
  }
  
}

export {
  zip ,
  
}
/* No side effect */
