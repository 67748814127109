// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Login$Weblab from "../components/Login.bs.js";
import * as Theme$Weblab from "../service/Theme.bs.js";
import * as ReactHelmet from "react-helmet";
import * as Search$Weblab from "../components/hub/Search.bs.js";
import * as Topbar$Weblab from "../components/Topbar.bs.js";
import * as HubBase$Weblab from "./HubBase.bs.js";
import * as Session$Weblab from "../service/Session.bs.js";
import * as Sidebar$Weblab from "../components/Sidebar.bs.js";
import * as Belt_HashMapInt from "rescript/lib/es6/belt_HashMapInt.js";
import * as MyOption$Weblab from "../service/MyOption.bs.js";
import * as Sidepane$Weblab from "../components/sidepane/Sidepane.bs.js";
import * as Core from "@material-ui/core";
import * as HubDashboard$Weblab from "../components/hub/HubDashboard.bs.js";
import * as NotebookCard$Weblab from "../components/hub/NotebookCard.bs.js";
import * as SidebarsBase$Weblab from "../components/SidebarsBase.bs.js";
import * as SupabaseClient$Weblab from "../bindings/supabase/SupabaseClient.bs.js";
import * as Styles from "@material-ui/core/styles";
import * as Sidepane_Settings$Weblab from "../components/sidepane/Sidepane_Settings.bs.js";
import Settings from "@material-ui/icons/Settings";
import ExpandMore from "@material-ui/icons/ExpandMore";

var useStyles = Styles.makeStyles(function (_theme) {
      return {
              heading: {
                padding: "16px"
              },
              grid: {
                display: "grid",
                padding: "24px",
                gridGap: "24px",
                gridTemplateColumns: "1fr 1fr 1fr 1fr"
              }
            };
    });

var Styles$1 = {
  useStyles: useStyles
};

function Hub(Props) {
  var match = React.useState(function () {
        
      });
  var setSession = match[1];
  var session = match[0];
  var match$1 = React.useReducer(HubBase$Weblab.reducer, {
        myNotebooks: undefined,
        mostViewed: undefined,
        mostViewedCount: undefined,
        search: undefined
      });
  var dispatch = match$1[1];
  var state = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setLoginDialog = match$2[1];
  var match$3 = React.useState(function () {
        return "0";
      });
  var setActiveTab = match$3[1];
  var activeTab = match$3[0];
  var toggle_active_tab = React.useCallback((function (tab) {
          if (activeTab !== tab) {
            return Curry._1(setActiveTab, (function (_x) {
                          return tab;
                        }));
          }
          
        }), [
        activeTab,
        setActiveTab
      ]);
  var match$4 = React.useState(function () {
        return false;
      });
  var setDarkMode = match$4[1];
  var darkMode = match$4[0];
  var globClasses = Curry._1(Theme$Weblab.Styles.useStyles, undefined);
  var classes = Curry._1(useStyles, undefined);
  React.useEffect((function () {
          Curry._1(setSession, (function (param) {
                  return Caml_option.nullable_to_opt(SupabaseClient$Weblab.supabase.auth.session());
                }));
          SupabaseClient$Weblab.supabase.auth.onAuthStateChange(function (param, session) {
                return Curry._1(setSession, (function (param) {
                              if (session == null) {
                                return ;
                              } else {
                                return Caml_option.some(session);
                              }
                            }));
              });
          Curry._1(setDarkMode, (function (param) {
                  return Theme$Weblab.initializeDarkMode(undefined);
                }));
          HubBase$Weblab.asyncReducer(dispatch, {
                TAG: /* AsyncInitialize */0,
                _0: session
              });
          
        }), []);
  React.useEffect((function () {
          HubBase$Weblab.asyncReducer(dispatch, {
                TAG: /* AsyncInitializeMyNotebooks */1,
                _0: session
              });
          
        }), [session]);
  var theme = React.useMemo((function () {
          return Styles.createTheme(Theme$Weblab.getThemeProto(darkMode));
        }), [darkMode]);
  var topbar = React.useMemo((function () {
          return React.createElement(Topbar$Weblab.make, {
                      setLoginDialog: setLoginDialog
                    });
        }), []);
  var sidebar = React.useMemo((function () {
          return React.createElement(Sidebar$Weblab.make, {
                      toggle_sidebar: SidebarsBase$Weblab.toggle_sidebar,
                      activeTab: activeTab,
                      toggle_active_tab: toggle_active_tab,
                      children: React.createElement(Core.Tooltip, {
                            children: React.createElement(Settings, {}),
                            title: "Settings"
                          })
                    });
        }), [
        activeTab,
        toggle_active_tab
      ]);
  var sidepane = React.useMemo((function () {
          return React.createElement(Sidepane$Weblab.make, {
                      activeTab: activeTab,
                      children: React.createElement(Sidepane_Settings$Weblab.make, {
                            toggle_sidebar: SidebarsBase$Weblab.toggle_sidebar,
                            darkMode: darkMode,
                            setDarkMode: setDarkMode
                          })
                    });
        }), [
        activeTab,
        darkMode
      ]);
  var myNotebooks = React.useMemo((function () {
          return Belt_Option.map(state.myNotebooks, (function (myNotebooks) {
                        return React.createElement(React.Fragment, undefined, React.createElement(Core.Typography, {
                                        children: "My Notebooks",
                                        className: classes.heading,
                                        variant: "h5",
                                        style: {
                                          backgroundColor: theme.palette.primary.main,
                                          color: "white",
                                          borderRadius: "4px"
                                        }
                                      }), React.createElement(Core.Box, {
                                        className: classes.grid,
                                        children: Belt_Array.map(Belt_HashMapInt.toArray(myNotebooks), (function (tup) {
                                                return React.createElement(NotebookCard$Weblab.make, {
                                                            notebook: tup[1],
                                                            dispatch: dispatch,
                                                            owner: /* Own */0,
                                                            key: "mynotebooks" + String(tup[0])
                                                          });
                                              }))
                                      }));
                      }));
        }), [state]);
  var mostViewed = React.useMemo((function () {
          return Belt_Option.map(MyOption$Weblab.zip(state.mostViewed, state.mostViewedCount), (function (tuple) {
                        return React.createElement(React.Fragment, undefined, React.createElement(Core.Typography, {
                                        children: "Most Viewed",
                                        className: classes.heading,
                                        variant: "h5",
                                        style: {
                                          backgroundColor: theme.palette.primary.main,
                                          color: "white",
                                          borderRadius: "4px"
                                        }
                                      }), React.createElement(Core.Box, {
                                        className: classes.grid,
                                        children: Belt_Array.mapWithIndex(tuple[0], (function (i, x) {
                                                return React.createElement(NotebookCard$Weblab.make, {
                                                            notebook: x,
                                                            dispatch: dispatch,
                                                            owner: /* Others */1,
                                                            key: "mostviewed" + String(i)
                                                          });
                                              }))
                                      }), React.createElement(Core.Box, {
                                        children: React.createElement(Core.Button, {
                                              onClick: (function (param) {
                                                  return HubBase$Weblab.asyncReducer(dispatch, {
                                                              TAG: /* AsyncLoadMostViewed */2,
                                                              _0: tuple[1]
                                                            });
                                                }),
                                              style: {
                                                left: "48%",
                                                position: "absolute"
                                              },
                                              children: null,
                                              color: "primary",
                                              variant: "outlined"
                                            }, React.createElement(ExpandMore, {}), "Load more"),
                                        height: 64,
                                        width: "100%"
                                      }));
                      }));
        }), [state.mostViewed]);
  var search = React.useMemo((function () {
          return Belt_Option.map(state.search, (function (arr) {
                        return React.createElement(Core.Box, {
                                    className: classes.grid,
                                    children: Belt_Array.mapWithIndex(arr, (function (i, x) {
                                            return React.createElement(NotebookCard$Weblab.make, {
                                                        notebook: x,
                                                        dispatch: dispatch,
                                                        owner: /* Others */1,
                                                        key: "search" + String(i)
                                                      });
                                          }))
                                  });
                      }));
        }), [state.search]);
  var tmp;
  if (search !== undefined) {
    tmp = Caml_option.valFromOption(search);
  } else if (myNotebooks !== undefined) {
    var myNotebooks$1 = Caml_option.valFromOption(myNotebooks);
    tmp = mostViewed !== undefined ? React.createElement(React.Fragment, undefined, myNotebooks$1, Caml_option.valFromOption(mostViewed)) : myNotebooks$1;
  } else {
    tmp = mostViewed !== undefined ? Caml_option.valFromOption(mostViewed) : null;
  }
  return React.createElement(Styles.ThemeProvider, {
              children: React.createElement(Session$Weblab.SessionContext.Provider.make, {
                    value: session,
                    children: null
                  }, React.createElement(Core.CssBaseline, {}), React.createElement(ReactHelmet.Helmet, {
                        children: null
                      }, React.createElement("link", {
                            href: "/favicon.png",
                            rel: "icon",
                            type: "image/png"
                          }), React.createElement("title", undefined, "Weblab NotebookHub")), React.createElement(Core.Box, {
                        className: globClasses.sidebar,
                        children: React.createElement("nav", undefined, sidebar),
                        bgcolor: theme.palette.background.default,
                        boxShadow: 2
                      }), React.createElement(Core.Box, {
                        className: globClasses.wrapper,
                        children: null,
                        gridTemplateColumns: {
                          xs: "48px 0px 1fr",
                          sm: "48px 0px 1fr",
                          md: "48px 0px 1fr"
                        },
                        id: "wrapper"
                      }, React.createElement(Core.Box, {
                            className: globClasses.topbar,
                            children: topbar,
                            boxShadow: 2
                          }), React.createElement(Core.Box, {
                            className: globClasses.sidepane,
                            children: sidepane,
                            bgcolor: theme.palette.background.default,
                            boxShadow: 3
                          }), React.createElement(Core.Box, {
                            style: {
                              gridColumn: "3",
                              gridRow: "3"
                            },
                            children: React.createElement("main", undefined, React.createElement(HubDashboard$Weblab.make, {
                                      children: null
                                    }, React.createElement(Search$Weblab.make, {
                                          dispatch: dispatch
                                        }), tmp))
                          })), React.createElement(Login$Weblab.make, {
                        loginDialog: match$2[0],
                        setLoginDialog: setLoginDialog
                      })),
              theme: theme
            });
}

Hub.displayName = "Hub";

var make = Hub;

var $$default = Hub;

export {
  Styles$1 as Styles,
  make ,
  $$default ,
  $$default as default,
  
}
/* useStyles Not a pure module */
