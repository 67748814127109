// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Errors$Weblab from "../../service/Errors.bs.js";
import * as HubBase$Weblab from "../../pagesComponents/HubBase.bs.js";
import * as Session$Weblab from "../../service/Session.bs.js";
import * as MyOption$Weblab from "../../service/MyOption.bs.js";
import * as Core from "@material-ui/core";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import HtmlReactParser from "html-react-parser";
import * as SupabaseClient$Weblab from "../../bindings/supabase/SupabaseClient.bs.js";
import Lock from "@material-ui/icons/Lock";
import * as Styles from "@material-ui/core/styles";
import Delete from "@material-ui/icons/Delete";
import SaveAlt from "@material-ui/icons/SaveAlt";
import LockOpen from "@material-ui/icons/LockOpen";
import MoreVert from "@material-ui/icons/MoreVert";
import OpenInNew from "@material-ui/icons/OpenInNew";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Description from "@material-ui/icons/Description";

var transOri = {
  horizontal: 0,
  vertical: -40
};

var useStyles = Styles.makeStyles(function (_theme) {
      return {
              cardMedia: {
                fontSize: "8px",
                height: "128px",
                margin: "12px",
                overflow: "hidden"
              }
            };
    });

var Styles$1 = {
  useStyles: useStyles
};

function NotebookCard(Props) {
  var notebook = Props.notebook;
  var dispatch = Props.dispatch;
  var owner = Props.owner;
  var match = React.useState(function () {
        
      });
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var handleClick = function ($$event) {
    var target = $$event.currentTarget;
    return Curry._1(setAnchorEl, (function (_x) {
                  return Caml_option.some(target);
                }));
  };
  var handleClose = function (_event, _reason) {
    return Curry._1(setAnchorEl, (function (_x) {
                  
                }));
  };
  var classes = Curry._1(useStyles, undefined);
  var session = React.useContext(Session$Weblab.SessionContext.context);
  var mySession = Belt_Option.flatMap(MyOption$Weblab.zip(session, Caml_option.nullable_to_opt(notebook.owner_id)), (function (tuple) {
          if (owner || tuple[0].user.id !== tuple[1]) {
            return ;
          } else {
            return tuple[0];
          }
        }));
  var name = notebook.name;
  if (name == null) {
    return null;
  }
  var svg = notebook.preview;
  var $$public = notebook.public;
  var arr = notebook.tags;
  var match$1 = notebook.url;
  var match$2 = notebook.id;
  var url = notebook.url;
  var match$3 = notebook.name;
  var match$4 = notebook.id;
  var match$5 = notebook.public;
  var match$6 = notebook.tags;
  return React.createElement(Core.Card, {
              children: null,
              style: {
                display: "flex",
                minWidth: "256px",
                flexDirection: "column"
              }
            }, (svg == null) ? React.createElement(Core.CardMedia, {
                    children: React.createElement(Description, {
                          color: "primary",
                          fontSize: "large"
                        }),
                    className: classes.cardMedia
                  }) : React.createElement(Core.CardMedia, {
                    children: HtmlReactParser(svg),
                    className: classes.cardMedia
                  }), React.createElement(Core.Divider, {}), React.createElement(Core.CardContent, {
                  children: null,
                  style: {
                    padding: "12px"
                  }
                }, React.createElement(Core.Typography, {
                      children: name.replace(".ijsnb", ""),
                      variant: "h6",
                      style: {
                        overflow: "hidden",
                        paddingBottom: "4px"
                      }
                    }), React.createElement(Core.Typography, {
                      children: "Views: " + String(Belt_Option.getWithDefault(Caml_option.nullable_to_opt(notebook.views), {
                                count: 0
                              }).count),
                      color: "textSecondary",
                      variant: "subtitle2"
                    }), React.createElement(Core.Typography, {
                      children: ($$public == null) ? null : (
                          $$public ? "public" : "private"
                        ),
                      color: "textSecondary",
                      variant: "subtitle2"
                    }), React.createElement(Core.Box, {
                      children: (arr == null) ? null : arr.split(/\s*[,;]\s*/).map(function (tag) {
                              if (tag !== undefined) {
                                return React.createElement(Core.Chip, {
                                            color: "primary",
                                            label: tag,
                                            size: "small"
                                          });
                              } else {
                                return null;
                              }
                            }),
                      display: "flex",
                      flexWrap: "wrap",
                      gridGap: 4,
                      mt: 1
                    })), React.createElement(Core.CardActions, {
                  children: null,
                  style: {
                    marginTop: "auto"
                  }
                }, !(match$1 == null) && !(match$2 == null) ? React.createElement(Core.Tooltip, {
                        children: React.createElement(Core.IconButton, {
                              href: "/?url=" + match$1,
                              onClick: (function (evt) {
                                  evt.preventDefault();
                                  var target = evt.currentTarget.href;
                                  SupabaseClient$Weblab.supabase.rpc("incrementviews", {
                                              row_id: match$2
                                            }).then(function (response) {
                                            var match = response.error;
                                            if (match == null) {
                                              return Promise.resolve(undefined);
                                            } else {
                                              return Promise.reject(Errors$Weblab.toExn({
                                                              RE_EXN_ID: Errors$Weblab.Message,
                                                              _1: match.message
                                                            }));
                                            }
                                          }).catch(function (error) {
                                          Errors$Weblab.alertError({
                                                TAG: /* Error */1,
                                                _0: Errors$Weblab.fromPromiseError(error)
                                              });
                                          return Promise.resolve(undefined);
                                        }).then(function (param) {
                                        location.href = target;
                                        return Promise.resolve(undefined);
                                      });
                                  
                                }),
                              children: React.createElement(OpenInNew, {}),
                              size: "small"
                            }),
                        title: "Open"
                      }) : null, React.createElement(Core.Tooltip, {
                      children: React.createElement(Core.IconButton, {
                            onClick: handleClick,
                            style: {
                              marginLeft: "auto"
                            },
                            children: React.createElement(MoreVert, {}),
                            size: "small"
                          }),
                      title: "More"
                    }), React.createElement(Core.Menu, {
                      keepMounted: true,
                      transformOrigin: transOri,
                      anchorEl: anchorEl,
                      children: null,
                      MenuListProps: {
                        dense: true,
                        disablePadding: true
                      },
                      onClose: handleClose,
                      open: Belt_Option.isSome(anchorEl),
                      transitionDuration: 0.2,
                      variant: "menu"
                    }, (url == null) ? null : React.createElement(Core.MenuItem, {
                            children: null
                          }, React.createElement(Core.ListItemIcon, {
                                children: React.createElement(SaveAlt, {
                                      fontSize: "small"
                                    })
                              }), React.createElement(Core.ListItemText, {
                                children: React.createElement("a", {
                                      style: {
                                        color: "black",
                                        fontSize: "14px",
                                        textDecoration: "none"
                                      },
                                      download: name,
                                      href: url,
                                      target: "_blank"
                                    }, "Download")
                              })), mySession !== undefined && !(match$3 == null) && !(match$4 == null) && !(match$5 == null) && !(match$6 == null) ? [
                        React.createElement(Core.MenuItem, {
                              children: null,
                              dense: true,
                              onClick: (function (evt) {
                                  Curry._1(setAnchorEl, (function (_x) {
                                          
                                        }));
                                  return HubBase$Weblab.asyncReducer(dispatch, {
                                              TAG: /* AsyncUpdateMyNotebook */4,
                                              _0: match$4,
                                              _1: {
                                                id: notebook.id,
                                                name: notebook.name,
                                                owner_id: notebook.owner_id,
                                                public: Js_null_undefined.fromOption(!match$5),
                                                views: notebook.views,
                                                preview: notebook.preview,
                                                created: notebook.created,
                                                tags: notebook.tags,
                                                url: notebook.url
                                              }
                                            });
                                })
                            }, React.createElement(Core.ListItemIcon, {
                                  children: match$5 ? React.createElement(Lock, {
                                          fontSize: "small"
                                        }) : React.createElement(LockOpen, {
                                          fontSize: "small"
                                        })
                                }), React.createElement(Core.ListItemText, {
                                  children: match$5 ? "Make private" : "Make public"
                                })),
                        React.createElement(Core.MenuItem, {
                              children: null,
                              dense: true,
                              onClick: (function (evt) {
                                  Curry._1(setAnchorEl, (function (_x) {
                                          
                                        }));
                                  var newTags = prompt("Please enter new tags.", match$6);
                                  if (!(newTags == null)) {
                                    return HubBase$Weblab.asyncReducer(dispatch, {
                                                TAG: /* AsyncUpdateMyNotebook */4,
                                                _0: match$4,
                                                _1: {
                                                  id: notebook.id,
                                                  name: notebook.name,
                                                  owner_id: notebook.owner_id,
                                                  public: notebook.public,
                                                  views: notebook.views,
                                                  preview: notebook.preview,
                                                  created: notebook.created,
                                                  tags: Js_null_undefined.fromOption(newTags),
                                                  url: notebook.url
                                                }
                                              });
                                  }
                                  
                                })
                            }, React.createElement(Core.ListItemIcon, {
                                  children: React.createElement(LocalOffer, {
                                        fontSize: "small"
                                      })
                                }), React.createElement(Core.ListItemText, {
                                  children: "Change tags"
                                })),
                        React.createElement(Core.MenuItem, {
                              children: null,
                              dense: true,
                              onClick: (function (evt) {
                                  Curry._1(setAnchorEl, (function (_x) {
                                          
                                        }));
                                  return HubBase$Weblab.asyncReducer(dispatch, {
                                              TAG: /* AsyncRemoveMyNotebook */3,
                                              _0: mySession,
                                              _1: match$3,
                                              _2: match$4
                                            });
                                })
                            }, React.createElement(Core.ListItemIcon, {
                                  children: React.createElement(Delete, {
                                        fontSize: "small"
                                      })
                                }), React.createElement(Core.ListItemText, {
                                  children: "Delete"
                                }))
                      ] : null)));
}

var make = NotebookCard;

export {
  transOri ,
  Styles$1 as Styles,
  make ,
  
}
/* useStyles Not a pure module */
