// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Session$Weblab from "../../service/Session.bs.js";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/core/styles";
import Weblab_logoSvg from "/src/assets/svg/weblab_logo.svg";

function HubDashboard(Props) {
  var children = Props.children;
  var session = React.useContext(Session$Weblab.SessionContext.context);
  var theme = Styles.useTheme();
  return React.createElement(Core.Container, {
              children: null
            }, session !== undefined ? null : React.createElement(Core.Box, {
                    clone: true,
                    children: React.createElement(Core.Card, {
                          children: React.createElement(Core.CardContent, {
                                children: null,
                                style: {
                                  textAlign: "center"
                                }
                              }, React.createElement(Core.Typography, {
                                    children: null,
                                    color: "primary",
                                    variant: "h3",
                                    style: {
                                      fontWeight: "700"
                                    }
                                  }, React.createElement(Core.Box, {
                                        clone: true,
                                        children: React.createElement(Weblab_logoSvg, {}),
                                        height: 64,
                                        width: 64
                                      }), "Notebook", React.createElement(Core.Box, {
                                        style: {
                                          color: theme.palette.secondary.main
                                        },
                                        children: "Hub",
                                        display: "inline"
                                      })), React.createElement(Core.Typography, {
                                    children: "Share your notebooks with the world.",
                                    variant: "subtitle1"
                                  }))
                        }),
                    margin: 2
                  }), React.Children.toArray(children));
}

var make = HubDashboard;

export {
  make ,
  
}
/* react Not a pure module */
