// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Delay$Weblab from "../../service/Delay.bs.js";
import * as Theme$Weblab from "../../service/Theme.bs.js";
import * as HubBase$Weblab from "../../pagesComponents/HubBase.bs.js";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/core/styles";

function Search(Props) {
  var dispatch = Props.dispatch;
  var match = React.useState(function () {
        return "";
      });
  var setSearch = match[1];
  var theme = Styles.useTheme();
  var darkMode = Theme$Weblab.getMode(theme);
  var delayedSearch = function (evt) {
    var value = evt.target.value;
    Curry._1(setSearch, (function (param) {
            return value;
          }));
    if (value === "") {
      return Delay$Weblab.delay((function (param) {
                    return Curry._1(dispatch, /* ClearSearch */1);
                  }), 0, undefined);
    } else {
      return Delay$Weblab.delay((function (x) {
                    return HubBase$Weblab.asyncReducer(dispatch, {
                                TAG: /* AsyncSearch */5,
                                _0: x
                              });
                  }), 1000, value);
    }
  };
  return React.createElement(Core.Box, {
              children: React.createElement(Core.TextField, {
                    autoFocus: true,
                    color: darkMode ? "secondary" : "primary",
                    fullWidth: true,
                    label: "Search tags",
                    onChange: delayedSearch,
                    value: match[0],
                    variant: "outlined"
                  }),
              display: "flex",
              padding: 6
            });
}

var make = Search;

export {
  make ,
  
}
/* react Not a pure module */
